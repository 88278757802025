import React, {Component} from 'react';
import {Modal} from "react-bootstrap";
import socket from "../../../Socket";
import {Event, decode, BRAND, formatAmount, fixDate, TOP_BANNER, REFERRAL_BANNER} from "../../../Helper";
import {Divider, Paper} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Refer from "../Affiliate/Refer";

class BannerReferral extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            effect: 'pulse',
            loading: true,
            players: [],
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        socket.on('PROMO_WINNERS', data => this.winners(decode(data)));
        Event.on('show_banner_referral', this.handleShow);
    }

    handleShow(e) {
        socket.emit('PROMO_WINNERS', 'AFFILIATE');
        this.setState({show: true, effect: 'pulse'});
    }

    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    winners = (data) => {
        this.setState({loading: false, players: data})
    };

    render() {
        let {show, effect} = this.state;
        const list = this.state.players.map((player, i) =>
            <Players key={i} index={i + 1} player={player}/>
        );

        return (
            <Modal contentClassName={'custom-modal-content'} size="lg" centered={true} show={show} onHide={this.handleClose} aria-labelledby="promo-md-modal"
                   className={'animated ' + effect}>
                <Modal.Header className={'p-0'} closeButton={false}>
                    <Modal.Title>
                        <img style={{width: '100%', borderRadius: '2px 2px 0px 0px'}} src={REFERRAL_BANNER} alt={'top banner'}/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{maxHeight: 500, display: 'flex'}} className={'p-2'}>
                    <div style={{fontSize: '0.81rem', overflowY: 'auto', overflowX: 'hidden'}}>

                        <Divider className={'pt-1'} textAlign={"left"} style={{color: 'antiquewhite'}}>Promotion Mechanics</Divider>

                        <ol style={{paddingLeft: '1rem', fontSize: 'smaller'}}>
                            <li>This promotion is meant to award the top Earning Affiliate weekly.</li>
                            <li>The ranking is based on the income made from weekly invited players.</li>
                            <li>Invite more Friends every week to Join & Play on JetX & Win!</li>
                            <li>Earnings made from past weeks’ invited players won’t count towards this week’s top Affiliate Ranking.</li>
                            <li>Affiliates will continue to enjoy their income from all players they’ve invited in the past.</li>
                        </ol>

                        <Divider textAlign={"left"} style={{color: 'antiquewhite'}}>Promotion Winners</Divider>

                        <Paper sx={{width: '100%', overflow: 'hidden'}}>
                            <TableContainer sx={{maxHeight: 211}}>
                                <Table style={{minHeight: '80px'}} stickyHeader size="small" aria-label="player list">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Date</TableCell>
                                            <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Promotion</TableCell>
                                            <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>User</TableCell>
                                            <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Earnings</TableCell>
                                            <TableCell className={'my-table-cell'} sx={{color: 'antiquewhite', backgroundColor: 'black'}}>Won[KES]</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>

                        <Divider className={'mt-2'} textAlign={"left"} style={{color: 'antiquewhite'}}>{BRAND} - Refer and Earn</Divider>

                        <Refer/>

                    </div>
                </Modal.Body>
                <Modal.Footer className={'p-2'}>
                    T&C Apply
                </Modal.Footer>
            </Modal>
        );
    }
}

class Players extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {player, index} = this.props;

        let username = player.name;
        let promotion = player.promotion;
        let narration = player.narration;
        let created = player.created;
        let amount_won = formatAmount(player.amount_won, 0);

        let row_color = (player.amount_won >= 5000) ? 'green' : '#ffffffcf';

        return (
            <TableRow key={index} sx={{backgroundColor: '#000000eb'}}>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{fixDate(created).substring(0, 16)}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{promotion}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{username}</TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}} align="left">
                    <div style={{borderRadius: '3rem', backgroundColor: 'rgb(44, 45, 48)', width: '50px', padding: '1px'}} className={'text-center'}>
                        {narration}
                    </div>
                </TableCell>
                <TableCell className={'my-table-cell'} sx={{color: row_color}}>{amount_won}&nbsp;&nbsp;&nbsp;</TableCell>
            </TableRow>
        );
    }
}

export default BannerReferral;