import {Tab, Tabs, TabList, TabPanel} from "react-tabs";
import PlayersTable from "./PlayersTable";
import React from "react";
import MyBets from "./MyBets";
import storage from "../../../Storage";
import Engine from "./Engine";
import Chat from "../../Pages/Parts/Chat";
import TopUsers from "./TopUsers";
import {encode, wait} from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";

function LowerPane(props) {
    let {game} = props;
    let token = storage.getKey('token');
    const {t} = props;

    const engine = Engine;

    function load() {
        wait(500).then(() => {
            engine.getPlayers();
        });
    }

    function loadMyBets() {
        socket.emit(C.MY_BETS, encode({token: token, game: game}));
    }

    return (
        <div>
            <Tabs>
                <TabList>
                    <Tab onClick={load}><span className={'text-white'}>All Bets</span></Tab>
                    <Tab onClick={loadMyBets}><span className={'text-white'}>Self</span></Tab>
                    <Tab><span className={'text-white'}>Chat</span></Tab>
                    <Tab><span className={'text-white'}>Top</span></Tab>
                </TabList>
                <TabPanel>
                    <PlayersTable t={props.t}/>
                </TabPanel>
                <TabPanel>
                    {(token !== null) &&
                        <MyBets engine={engine} t={t} game={game}/>
                    }
                    {(token === null) &&
                        <div className={'bg-dark-pc alert font-13 text-danger'}>[Please log in!]</div>
                    }
                </TabPanel>
                <TabPanel>
                    <Chat t={t}/>
                </TabPanel>
                <TabPanel>
                    <TopUsers t={t}/>
                </TabPanel>
            </Tabs>
        </div>
    );
}

export default LowerPane;