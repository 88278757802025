import React, {Component} from "react";
import {Box, Grid} from "@mui/material";
import socket from "../../../Socket";
import {decode, formatAmount} from "../../../Helper";
import C from "../../../Constant";

class ReferrerWeekly extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            items: [],
        }
    }

    componentDidMount() {
        if (this._isMounted) {
            socket.emit(C.TOP_REFERRERS, 3);
            socket.on(C.TOP_REFERRERS, data => this.setTopRefs(decode(data)));
        }
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    setTopRefs(data) {
        this.setState({items: data});
    }

    render() {
        const list = this.state.items.map((item, i) =>
            <Items key={i} index={i + 1} item={item}/>
        );

        return (
            <Box sx={{flexGrow: 1}}>
                <Grid container spacing={0}>
                    <Grid style={{display: 'inline-flex'}} xs={12}>
                        Top Referrers {list.length === 0 ? <div>&nbsp;|&nbsp;Loading</div> : list}
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

class Items extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {item} = this.props;

        let username = item.name;
        let user_id = item.user_id;
        let total_earnings = formatAmount(item.total_earnings);

        return (
            <div>
                &nbsp;|&nbsp;<small style={{color: 'whitesmoke'}}>{username}</small>: <b style={{color: 'black'}}>{total_earnings}/-</b>
            </div>
        );
    }
}

export default ReferrerWeekly;