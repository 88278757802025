import React from 'react'
import storage from "../../../Storage";
import socket from "../../../Socket";
import C from "../../../Constant";
import {SITE, BRAND, REFERRAL_PERCENTAGE, BORDER_RADIUS, formatAmount, REFERRAL_BANNER} from "../../../Helper";
import {Card, Divider, Paper} from "@mui/material";
import Diversity1Icon from '@mui/icons-material/Diversity1';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import formatCurrency from "format-currency";
import Withdraw from "./Withdraw";
import List from "./List";
import Earnings from "./Earnings";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

export default class Refer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token') ? storage.getKey('token') : null,
            user_id: storage.getKey('user_id') ? storage.getKey('user_id') : null,
            logged: false,
            earnings: 0,
            referral_count: 0,
            copySuccess: ''
        };
    }

    componentWillMount() {
        if (this.state.token !== null) {
            this.setState({logged: true});
        }
        socket.on(C.MY_REFERRAL, data => this.setReferralDetails(data));
    }

    setReferralDetails = (data) => {
        this.setState({earnings: data.earnings, referral_count: data.referral_count});
    }

    copyToClipboard = (e) => {
        navigator.clipboard.writeText(SITE + '/win/' + this.state.user_id).then(r => {
            this.setState({copySuccess: 'Link copied to clipboard!'});
        });
    };

    render() {
        let {logged, earnings, referral_count} = this.state;

        return (
            <>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <img style={{width: '100%', borderRadius: '4px 4px 0px 0px'}} src={REFERRAL_BANNER} alt={'refer and earn'}/>
                                <Divider textAlign={"left"} style={{color: 'gold'}}>Winners List</Divider>
                                <Paper sx={{width: '100%', overflow: 'hidden'}}>
                                    <TableContainer sx={{maxHeight: 211}}>
                                        <Table style={{minHeight: '80px'}} stickyHeader size="small" aria-label="player list">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d', backgroundColor: 'black'}}>Date</TableCell>
                                                    <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d', backgroundColor: 'black'}}>Promotion</TableCell>
                                                    <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d', backgroundColor: 'black'}}>User</TableCell>
                                                    <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d', backgroundColor: 'black'}}>Referrals</TableCell>
                                                    <TableCell className={'my-table-cell'} sx={{color: '#ffffff7d', backgroundColor: 'black'}}>Won[KES]</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                -- List loading --
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>
                            <div className={'col-8'}>
                                <p className={'m-0'} style={{color: '#ffffff'}}>
                                    Meanwhile, you can also invite your Friends to get <strong className={'text-warning'}>{REFERRAL_PERCENTAGE}%</strong> on every deposit they
                                    make.
                                </p>
                            </div>
                            <div className={'col-4'}>
                                <Diversity1Icon className={'text-warning'} style={{width: '50px', height: 'auto'}}/>
                            </div>
                        </div>

                        {logged ?
                            <div className={'text-center'}>
                                <Divider textAlign={"left"} style={{color: 'gold'}}>Referral Link</Divider>

                                <p>Copy & Share Your Referral Link below</p>

                                <div onClick={this.copyToClipboard} className={"mb-2 text-warning"} style={{
                                    height: '37px',
                                    borderRadius: BORDER_RADIUS,
                                    backgroundColor: 'green',
                                    padding: '0px 16px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }}>
                                    <span style={{flex: '0 1 90%', whiteSpace: 'nowrap'}}>
                                        {SITE + '/win/' + this.state.user_id}
                                    </span>
                                    <i style={{fontSize: '1.5em'}} className={"fa fa-copy"}/>
                                </div>
                                <span className={"text-success"}>{this.state.copySuccess}</span>

                                <Divider textAlign={"left"} style={{color: 'gold'}}>Referral Account</Divider>

                                <div style={{margin: '0.1rem'}} className="row text-center">
                                    <div className={'col-3 p-0'}>
                                        <div className={"my-gradient mr-1 border-right"}>
                                            <span className={'text-warning'}>Total Referrals</span><br/>
                                            <span className="text-white font-15">{formatAmount(referral_count, 0)}</span>
                                        </div>
                                    </div>
                                    <div className={'col-3 p-0'}>
                                        <div className={'my-gradient mr-1 border-right'}>
                                            <span className={'text-warning'}>Total Earnings</span><br/>
                                            <span className="text-white font-15">KES {formatCurrency(parseFloat(earnings).toFixed(2))}</span><br/>
                                        </div>
                                    </div>
                                    <div className={'col-6 p-0'}>
                                        <div className={'my-gradient mr-1 border-right'}>
                                            <p style={{fontSize: '0.8em'}} className={"text-warning m-0"}>Withdraw to Wallet or M-PESA:</p>
                                            <Withdraw earnings={earnings}/>
                                        </div>
                                    </div>
                                </div>

                                <Divider textAlign={"left"} style={{color: 'gold'}}>Referral Statistics</Divider>

                                <Tabs>
                                    <TabList>
                                        <Tab><span className={'text-white'}>Latest Referrals</span></Tab>
                                        <Tab><span className={'text-white'}>Latest Earnings</span></Tab>
                                    </TabList>
                                    <TabPanel style={{minHeight: '100px', color: 'whitesmoke'}}>
                                        <List/>
                                    </TabPanel>
                                    <TabPanel style={{minHeight: '100px', color: 'whitesmoke'}}>
                                        <Earnings/>
                                    </TabPanel>
                                </Tabs>
                            </div>
                            :
                            <span className={'text-warning'}>Please login to see your link.</span>
                        }
                    </div>
                </div>
            </>
        );
    }
}